import { lazy } from "react";

const routes = [
  {
    path: "chyroDashboard",
    component: lazy(() => import("../features/Dashboard/ChyroDashboard")),
    exact: true,
  },
  {
    path: "clients",
    component: lazy(() => import("../features/Clients/ClientList/ClientList")),
    exact: true,
  },
  {
    path: "clients/viewclient/:clientId",
    component: lazy(() => import("../features/Clients/ViewClient/ViewClient")),
    exact: true,
  },
  {
    path: "patients",
    component: lazy(() =>
      import("../features/Patients/PatientList/PatientList")
    ),
    exact: true,
  },
  {
    path: "patients/viewpatient/:PatientId",
    component: lazy(() =>
      import("../features/Patients/Viewpatient/Viewpatient")
    ),
    exact: true,
  },
  {
    path: "singleviewpatient",
    component: lazy(() =>
      import("../features/Patients/SinglePatientView/SinglePatientView")
    ),
    exact: true,
  },
  {
    path: "viewpatient/compare",
    component: lazy(() =>
      import(
        "../features/Patients/VistThermalPostureCompare/VistThermalPostureCompare"
      )
    ),

    exact: true,
  },
  {
    path: "patients/viewpatient/:PatientId/healthcompare",
    component: lazy(() =>
      import("../features/Patients/Viewpatient/CompareHealthReport.js")
    ),
    exact: true,
  },
  {
    path: "patients/viewpatient/:PatientId/PatientVisitDetails/:visitId",
    component: lazy(() =>
      import("../features/Patients/PatientVisitDetails/PatientVisitDetails")
    ),
    exact: true,
  },

  // {
  //   path: 'users',
  //   component: lazy(() => import('features/Users')),
  //   exact: true,
  // },
];

export default routes;
