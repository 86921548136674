import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ViewPatientRecords: null,
  PatientTestRecords: [],
  CompareHealthRecords: [],
  currentPage: 1,
  totalPages: 0,
};

export const ViewPatientSlice = createSlice({
  name: "viewpatient",
  initialState,
  reducers: {
    getViewPatientRecords: (state, action) => {
      state.ViewPatientRecords = action.payload;
    },
    getPatientTestRecords: (state, action) => {
      state.PatientTestRecords = action.payload.data;
      state.totalPages = action.payload.totalPages;
    },
    getComparePatientRecords: (state, action) => {
      state.CompareHealthRecords = action.payload;
    },
  },
});

export const {
  getViewPatientRecords,
  getPatientTestRecords,
  getComparePatientRecords,
} = ViewPatientSlice.actions;

export default ViewPatientSlice.reducer;
