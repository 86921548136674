import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  PatientVisitRecords: [],
};

export const PatientVisitDetailsSlice = createSlice({
  name: "patientvisitDetails",
  initialState,
  reducers: {
    getPatientVisitRecords: (state, action) => {
      state.PatientVisitRecords = action.payload;
    },
  },
});

export const { getPatientVisitRecords } = PatientVisitDetailsSlice.actions;

export default PatientVisitDetailsSlice.reducer;
