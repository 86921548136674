import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  PatientListRecords: [],
  currentPage: 1,
  totalPages: 0,
};

export const DashboardRecentVisitsSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getRecentVisitsRecords: (state, action) => {
      state.PatientListRecords = action.payload.data;
      state.totalPages = action.payload.totalPages;
    },
  },
});

export const { getRecentVisitsRecords } = DashboardRecentVisitsSlice.actions;

export default DashboardRecentVisitsSlice.reducer;
