import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

export const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isAuthenticated: false,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.token = JSON.stringify(action.payload.token);
      localStorage.setItem("isAuthenticated", state.isAuthenticated);
      localStorage.setItem("token", state.token);
      localStorage.setItem("token_bearer", action.payload.token);
      localStorage.setItem("token_type", action.payload.token_Type);
      if (action.payload.roles) {
        state.roles = action.payload.roles;
        localStorage.setItem("roles", action.payload.roles);
      }
      const decoded = jwtDecode(action.payload.token);
      if (decoded) {
        state.user = JSON.stringify(decoded);
        localStorage.setItem("user", JSON.stringify(decoded));
        localStorage.setItem("userFirstName", decoded.firstname);
        localStorage.setItem("userLastName", decoded.lastname);
        localStorage.setItem("userId", decoded.userid);
      }
    },
    loginSessionAvailable(state) {
      let token = localStorage.getItem("token");
      let token_type = localStorage.getItem("token_type");
      let token_bearer = localStorage.getItem("token_bearer");
      if (token && token_type && token_bearer) {
        const decoded = jwtDecode(token);
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        if (date === undefined || !(date.valueOf() > new Date().valueOf())) {
          state.isAuthenticated = false;
        } else {
          state.isAuthenticated = true;
        }
      } else {
        state.isAuthenticated = false;
      }
    },
    loginFailure(state) {
      //state.loader = false;
      state.isAuthenticated = false;
    },
  },
});

export const { loginSuccess, loginSessionAvailable, loginFailure } =
  AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
