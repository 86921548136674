import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  patientDetailsByVisit: null,
};

const SinglePatientViewSlice = createSlice({
  name: "SinglePatientViewSlice",
  initialState,
  reducers: {
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
    getPatientDetailsByVisit(state, action) {
      state.patientDetailsByVisit = action.payload;
    },
  },
});

export const { showLoader, hideLoader, getPatientDetailsByVisit } =
  SinglePatientViewSlice.actions;

export default SinglePatientViewSlice.reducer;
