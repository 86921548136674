import React, { useEffect, useState } from "react";
import { Button, QRCode, Segmented, Space } from "antd";
import logo from "../../logo.png";
function doDownload(url, fileName) {
  const a = document.createElement("a");
  a.download = fileName;
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
const downloadCanvasQRCode = () => {
  const canvas = document.getElementById("myqrcode")?.querySelector("canvas");
  if (canvas) {
    const url = canvas.toDataURL();
    doDownload(url, "QRCode.png");
  }
};
const downloadSvgQRCode = () => {
  const svg = document.getElementById("myqrcode")?.querySelector("svg");
  const svgData = new XMLSerializer().serializeToString(svg);
  const blob = new Blob([svgData], {
    type: "image/png/svg+xml;charset=utf-8",
  });
  const url = URL.createObjectURL(blob);
  doDownload(url, "QRCode.svg");
};
const App = ({ values }) => {
  console.log("Values", values);
  useEffect(() => {}, [values]);

  var str = "";
  if (values) {
    str = `Name: ${values?.firstName} Age: ${values?.age} Gender: ${values?.gender} ID: ${values?.userid}`;
    console.log("str", str);
  }
  const [renderType, setRenderType] = React.useState("svg");
  return (
    <Space id="myqrcode" direction="vertical">
      {/* <Segmented
        options={["canvas", "svg"]}
        onChange={(val) => setRenderType(val)}
      /> */}
      <div>
        <QRCode
          type={renderType}
          value={JSON.stringify(str)}
          bgColor="#fff"
          style={{
            marginBottom: 16,
          }}
          icon={logo}
          //   icon="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
        />
        {values && (
          <Button
            type="primary"
            onClick={
              renderType === "canvas" ? downloadCanvasQRCode : downloadSvgQRCode
            }>
            Download
          </Button>
        )}
      </div>
    </Space>
  );
};
export default App;
