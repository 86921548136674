import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  PatientListRecords: [],
  currentPage: 1,
  totalPages: 0,
};

export const PatientListSlice = createSlice({
  name: "patientlist",
  initialState,
  reducers: {
    getPatientListRecords: (state, action) => {
      state.PatientListRecords = action.payload.data;
      state.totalPages = action.payload.totalPages;
    },
  },
});

export const { getPatientListRecords } = PatientListSlice.actions;

export default PatientListSlice.reducer;
