import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientListRecords: [],
  currentPage: 1,
  totalPages: 0,
};

export const ClientListSlice = createSlice({
  name: "clientList",
  initialState,
  reducers: {
    getClientListRecords: (state, action) => {
      state.clientListRecords = action.payload.data;
      state.totalPages = action.payload.totalPages;
    },
  },
});

export const { getClientListRecords } = ClientListSlice.actions;

export default ClientListSlice.reducer;
