import { postRequest } from "../../app/axiosClient";
import { message } from "antd";
import { getRecentVisitsRecords } from "./DashboardSlice";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";

export const getRecentVisitsApi = (values) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Dashboard/GetRecentPatientsList`, values)
      .then((res) => {
        dispatch(
          getRecentVisitsRecords({
            data: res.data.items,
            totalPages: res.data.totalPages,
          })
        );
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        message.error("Patient list not found");
      });
  } catch (e) {
    message.error("Error");
  }
};
