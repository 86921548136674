import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  machineData: [],
  ClientData: null,
};

export const ViewClientSlice = createSlice({
  name: "viewClient",
  initialState,
  reducers: {
    getMachineRecords: (state, action) => {
      state.machineData = action.payload;
    },
    getClientRecords: (state, action) => {
      state.ClientData = action.payload;
    },
  },
});

export const { getMachineRecords, getClientRecords } = ViewClientSlice.actions;

export default ViewClientSlice.reducer;
