const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export default constants;

export const LOCALHOST = "https://localhost:7014/";

