import { Row, Col, Form, Input, Button, Checkbox, Card, Flex } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import logo1 from "../../logo.png";
import { useNavigate } from "react-router-dom";
import { LoginAPI } from "./AuthenticationAPI";
function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onSubmit = async () => {
    const values = await form.validateFields();
    dispatch(LoginAPI(values));
  };
  return (
    <>
      <div style={{ height: "calc(100vh - 1px)" }}>
        <Row justify="center" className="h-100 login-main" align="middle">
          <Col xs={20} md={20} lg={18} xl={10} style={{ marginTop: "-30px" }}>
            <div className="text-center loginpage">
              <img src={logo1} className="logo-single" alt="" />
              <h1 className="tx-dark mg-b-5 tx-left">
                Sign in to your account
              </h1>
              {/* <p>
                Clarity gives you the blocks and components you need to create a
                truly professional website.
              </p> */}
            </div>
            <Row justify="center" align="middle">
              <Col span={20}>
                <Card>
                  <div className="mb-2"></div>
                  <Form
                    form={form}
                    name="normal_login"
                    layout="vertical"
                    autoComplete="off"
                    initialValues={{ remember: false }}
                  >
                    <Form.Item name="userName">
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email address"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item name="password">
                      <Input
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                        size="large"
                      />
                    </Form.Item>
                    {/* <Form.Item>
                      <Flex gap="middle" align="center" justify="space-between">
                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <a href="#">Forgot password</a>
                      </Flex>
                    </Form.Item> */}
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        block
                        onClick={() => onSubmit()}
                      >
                        Sign In
                      </Button>
                    </Form.Item>
                  </Form>
                  {/* <p className="text-center">
                    Patient login <a href="">Sign In</a>
                  </p> */}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LoginPage;
